import React, { useState, useEffect } from "react"
import chatIcon from "../images/common/chat-left-dots-fill.svg"
import styles from "./chat.module.scss"
import Button from "./button"
import CloseButton from "./closeButton"
import successImage from "../images/success_message.svg"
import spinnerImg from "../images/blog/spinner.svg"

const Chat = ({ translations }) => {
  const [isFormOpen, setIsFormOpen] = useState(false)
  const [responseStatus, setResponseStatus] = useState(null)
  const [name, setName] = useState("")
  const [email, setEmail] = useState("")
  const [message, setMessage] = useState("")
  const [isLoading, setIsLoading] = useState(false)
  const [visibleOnMobile, setVisibleOnMobile] = useState(false)

  const handleScroll = () => {
    const heightToShow = 80
    const winScroll =
      document.body.scrollTop || document.documentElement.scrollTop
    if (winScroll < heightToShow) {
      setVisibleOnMobile(false)
    } else {
      !visibleOnMobile && setVisibleOnMobile(true)
    }
  }

  useEffect(() => {
    document.addEventListener("scroll", handleScroll)
    return () => {
      document.removeEventListener("scroll", handleScroll)
    }
  }, [])

  const openForm = () => {
    setIsFormOpen(true)
    if (document.documentElement.clientWidth <= 520) {
      const html = document.querySelector('html')
      const body = document.querySelector('body')
      html.style.overflow = 'hidden'
      html.style.touchAction = 'none'
      html.style.height = '-webkit-fill-available'
      body.style.overflow = 'hidden'
      body.style.touchAction = 'none'
    }
  }

  const closeForm = () => {
    setResponseStatus(null)
    setIsFormOpen(false)
    setName("")
    setEmail("")
    setMessage("")
    const html = document.querySelector('html')
    const body = document.querySelector('body')
    html.style.overflow = 'visible'
    html.style.touchAction = 'auto'
    html.style.height = 'inherit'
    body.style.overflow = 'visible'
    body.style.touchAction = 'auto'
  }

  const handleSubmit = async (e) => {
    e.preventDefault()
    if (name && email && message) {
      setIsLoading(true)
      try {
        const response = await fetch(
          "https://i7sjujgi2e.execute-api.us-east-1.amazonaws.com/contactEmail",
          {
            method: "POST",
            body: JSON.stringify({
              location: window.location.href,
              name,
              email,
              message,
            }),
          }
        )
        if (response.ok) {
          setResponseStatus("success")
        } else {
          throw new Error("Something went wrong")
        }
      } catch (error) {
        console.log(error)
      }
      setIsLoading(false)
    }
  }

  return (
    <div
      className={
        visibleOnMobile || isFormOpen
          ? [styles.chatFabWrapper, styles.visibleOnMobile].join(" ")
          : styles.chatFabWrapper
      }
    >
      {isFormOpen && (
        <div className={styles.scrollable}>
          <div className={styles.wrapper}>
            <CloseButton onClick={closeForm} />
            {!responseStatus ? (
              <form id="landing-chat" onSubmit={handleSubmit}>
                <label for="name">{translations.chat.full_name}</label>
                <input
                  id="name"
                  name="name"
                  value={name}
                  onChange={(e) => setName(e.target.value)}
                  type="text"
                  autoCapitalize
                  autoFocus={true}
                />
                <label for="email">{translations.chat.email}</label>
                <input
                  id="email"
                  name="email"
                  value={email}
                  onChange={(e) => setEmail(e.target.value)}
                  type="email"
                />
                <label for="message">{translations.chat.message}</label>
                <textarea
                  id="message"
                  name="message"
                  value={message}
                  onChange={(e) => setMessage(e.target.value)}
                  rows="4"
                  placeholder={translations.chat.message_placeholder}
                />
                <Button
                  type="submit"
                  classStyle={styles.submitButton}
                  disabled={isLoading}
                >
                  {isLoading ? (
                    <img src={spinnerImg} className={styles.spinner} />
                  ) : (
                    translations.chat.send_message
                  )}
                </Button>
              </form>
            ) : (
              <div className={styles.confirmWrapper}>
                <img src={successImage} alt="success image" />
                <h3>{translations.chat.success_message}</h3>
                <Button classStyle={styles.confirmButton} onClick={closeForm}>
                  {translations.chat.success}
                </Button>
              </div>
            )}
          </div>
        </div>
      )}
      <button
        className={styles.fab}
        onClick={() => {
          if (isFormOpen) {
            closeForm()
          } else {
            openForm()
          }
        }}
      >
        <img src={chatIcon} alt="chat" />
      </button>
    </div>
  )
}

export default Chat
