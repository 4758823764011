import React from "react"
import { Link, graphql } from "gatsby"
import styles from "./footer.module.scss"
import globalStyles from "../services/globalStyles.module.scss"
import NavFooter from "./navFooter"
import logo from "../images/common/sideline_logo.svg"
import Translate from "./translate";
import { langPath } from "../services/languageService"

class Footer extends React.Component {
  render() {
    const { translations, languageCode } = this.props;
    return (
      <footer>
        {/* <div className={[globalStyles.contentContainer190, globalStyles.contentContainerMobile40].join(' ')}>
          <NavFooter translations={translations} languageCode={languageCode} />
        </div> */}
        <div
          className={[styles.bottomContainer, globalStyles.contentContainer190, globalStyles.contentContainerMobile40].join(' ')}>
          <img src={logo} style={{ marginTop: 20 }} loading="lazy" />
          <span>
            <span>@ {new Date().getFullYear()} Sideline Sports. </span>
            <Translate template={translations.all_rights_reserved}>
              <Link className={styles.linkStyles} to={langPath(languageCode)('https://sidelinesports.com/legal')} />
              <Link className={styles.linkStyles} to={langPath(languageCode)('https://sidelinesports.com/privacyPolicy')} />
            </Translate>
          </span>
        </div>
      </footer>
    )
  }
}


export const query = graphql`
fragment FooterTranslations on TextsYaml{
  sports_title
  pricing_title
  support_title
  explore
  resources_title
  customers_title
  testimonials_title
  contact_title
  blog_title
  about_us_title
  downloads_title
  legal_title
  press_kit_title
  social_media_title
  all_rights_reserved
  sports {
    basketball
    field_hockey
    floorball
    handball
    ice_hockey
    performance
    soccer
    tennis
    volleyball
    other_sports
  }
}
`

export default Footer
