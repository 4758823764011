import axios from "axios"
import Cookies from "js-cookie"

import { countries } from "./countries"
import { roles } from "./roles"

export const FREE_TRIAL_FORM_GUID = "026e2ffb-604e-48f6-8e1f-b9cb666f88ef"
export const BOOK_A_DEMO_FORM_GUID = "518162f7-5823-423e-bc3b-e17c3871b166"

const ACCOUNT_ID = "27217931"

export const trackHubspotFormData = async (formGuid, formValues) => {
  const [firstName, lastName] = (formValues.name ?? "").split(" ")

  await axios.post(
    `https://api.hsforms.com/submissions/v3/integration/submit/${ACCOUNT_ID}/${formGuid}`,
    {
      submittedAt: new Date().getTime(),
      fields: [
        {
          objectTypeId: "0-1",
          name: "email",
          value: formValues.email,
        },
        {
          objectTypeId: "0-1",
          name: "firstname",
          value: firstName,
        },
        {
          objectTypeId: "0-1",
          name: "lastname",
          value: lastName,
        },
        ...(formValues.country
          ? [
              {
                objectTypeId: "0-1",
                name: "country",
                value: countries[formValues.country].name,
              },
            ]
          : []),
        ...(formValues.sport
          ? [
              {
                objectTypeId: "0-1",
                name: "sport",
                value: formValues.sport,
              },
            ]
          : []),
        ...(formValues.organization
          ? [
              {
                objectTypeId: "0-1",
                name: "organization_name",
                value: formValues.organization,
              },
            ]
          : []),
        ...(formValues.role !== undefined && !isNaN(Number(formValues.role))
          ? [
              {
                objectTypeId: "0-1",
                name: "role",
                value: roles[Number(formValues.role) - 1],
              },
            ]
          : []),
        ...(formValues.phone
          ? [
              {
                objectTypeId: "0-1",
                name: "phone",
                value: `${
                  formValues.country
                    ? `+${countries[formValues.country].code}`
                    : ""
                }${formValues.phone}`,
              },
            ]
          : []),
        ...(formValues.message
          ? [
              {
                objectTypeId: "0-1",
                name: "message",
                value: formValues.message,
              },
            ]
          : []),
      ],
      context: {
        hutk: Cookies.get("hubspotutk"),
        pageUri:
          window?.location?.hostname?.replace("wwww.", "") +
            window?.location?.pathname || "xpsnetwork.app",
        pageName: "Sideline Sports Landing Page",
      },
    }
  )
}
