import { graphql, Link, StaticQuery } from "gatsby"
import throttle from 'lodash.throttle'
import React from "react"
import logo from "../images/logo.svg"
import logoAlternative from "../images/logo_white.svg"
import globalStyles from "../services/globalStyles.module.scss"
import { langPath } from "../services/languageService"
import styles from "./header.module.scss"

class Header extends React.Component {
  constructor(props) {
    super(props);
    this.state = {
      headerOffsetReached: false,
      pricingRoute: props.pricingRoute,
      expandedMobileMenu: ''
    }
    this.onScroll = this.onScroll.bind(this);
    this.disableBodyScroll = this.disableBodyScroll.bind(this);
    this.changeWhiteFont = this.changeWhiteFont.bind(this);
    this.linkClicked = this.linkClicked.bind(this);
  }
  componentDidMount() {
    document.addEventListener("scroll", throttle(this.onScroll.bind(this), 250));
  }
  componentWillUnmount() {
    document.removeEventListener("scroll", throttle(this.onScroll.bind(this), 250));
  }
  getBodyScrollTop() {
    const el = document.scrollingElement || document.documentElement;
    return el.scrollTop;
  }

  getBodyWidth() {
    const el = document.scrollingElement || document.documentElement;
    return el.clientWidth;
  }

  onScroll() {
    const { headerFullYOffset, mobileHeaderFullYOffset, fixed, noScrollAction, whiteFont, staticHeader } = this.props;
    const { header1, darkLogo, whiteLogo } = this.refs;

    if (!header1 || noScrollAction || (!fixed && !staticHeader)) {
      return;
    }
    if (header1 && this.getBodyWidth() < 1000) {
      if (this.getBodyScrollTop() > (mobileHeaderFullYOffset ? mobileHeaderFullYOffset : header1.offsetTop)) {
        header1.classList.add(styles.fadeInHeaderContainerAndLogo);
        whiteFont && darkLogo.classList.add(styles.fullOpacity);
        whiteFont && whiteLogo.classList.add(styles.noneOpacity);
        this.setState({ headerOffsetReached: true });
      } else {
        header1.classList.remove(styles.fadeInHeaderContainerAndLogo);
        whiteFont && darkLogo.classList.remove(styles.fullOpacity);
        whiteFont && whiteLogo.classList.remove(styles.noneOpacity);
        this.setState({ headerOffsetReached: false });

      }
    }
    else if (header1) {
      if (this.getBodyScrollTop() > (headerFullYOffset ? headerFullYOffset : header1.offsetTop)) {
        header1.classList.add((fixed || staticHeader) ? styles.sticky : globalStyles.hidden);
        whiteFont && darkLogo.classList.add(styles.fullOpacity);
        whiteFont && whiteLogo.classList.add(styles.noneOpacity);
        this.setState({ headerOffsetReached: true });

      } else {
        header1.classList.remove((fixed || staticHeader) ? styles.sticky : globalStyles.hidden);
        whiteFont && darkLogo.classList.remove(styles.fullOpacity);
        whiteFont && whiteLogo.classList.remove(styles.noneOpacity);
        this.setState({ headerOffsetReached: false });

      }
    }
  }

  disableBodyScroll(e) {
    document.body.style.overflow = e.target.checked ? 'hidden' : 'scroll';
  }

  changeWhiteFont(e) {
    if (!this.state.headerOffsetReached) {
      const { whiteFont } = this.props;
      const { darkLogo, whiteLogo } = this.refs;

      if (e.target.checked) {
        whiteFont && darkLogo.classList.add(styles.fullOpacity);
        whiteFont && whiteLogo.classList.add(styles.noneOpacity);
      } else {
        whiteFont && darkLogo.classList.remove(styles.fullOpacity);
        whiteFont && whiteLogo.classList.remove(styles.noneOpacity);
      }

    }
  }

  linkClicked(urlParam) {
    const location = document.location.href.split("/");
    if (urlParam === location[location.length - 1]) this.toggleMobileMenu(false)
    this.disableBodyScroll({ target: { checked: false } });
  }

  toggleMobileMenu(open) {
    if (this.refs.checkbox) {
      this.refs.checkbox.checked = open;
    }
  }

  hideDropdown() {
    if (this.refs.dropdownContent) {
      this.refs.dropdownContent.style.opacity = 0;
      setTimeout(() => {
        if (this.refs.dropdownContent) {
          this.refs.dropdownContent.style.opacity = ''
          this.refs.dropdownContent.style.display = 'none'
          setTimeout(() => this.refs.dropdownContent.style.display = '', 250);
        }
      }, 300);
    }
  }

  componentWillReceiveProps(newProps) {
    // if (newProps.pricingRoute != this.state.pricingRoute) {
    //   this.setState({ pricingRoute: newProps.pricingRoute });
    // }
  }

  render() {
    const { fixed, headerBackground, whiteFont, nologo, className, logoOnMobile, num, boxShadow, seeThrough, languageCode } = this.props;
    const link = langPath(languageCode);
    return <header ref={"header" + num} className={[styles.header, seeThrough && !fixed && styles.seeThrough, boxShadow && styles.boxShadow, fixed && styles.preSticky, headerBackground && styles.headerBackground, className].join(' ')}>
      {!nologo &&
        <Link className={[styles.logoContainer, styles.logoDesktop].join(' ')} to={link('/')}>
          <img src={fixed ? logo : (whiteFont ? logoAlternative : logo)} style={{ marginBottom: 0 }} alt="XPS Network" />
        </Link>}
      {!nologo &&
        <Link className={[styles.logoContainer, styles.logoMobile].join(' ')} to={link('/')}>
          <img ref="darkLogo" className={whiteFont && styles.noneOpacity} src={logo} style={{ marginBottom: 0 }} alt="XPS Network" />
          {whiteFont && <img ref="whiteLogo" src={logoAlternative} style={{ marginBottom: 0 }} alt="XPS Network" />}
        </Link>}
      {logoOnMobile &&
        <Link onClick={() => { this.linkClicked("") }} to={link('/')} className={[styles.logoContainer, styles.logoMobile].join(' ')}>
          <img src={fixed ? logo : (whiteFont ? logoAlternative : logo)} style={{ marginBottom: 0 }} alt="XPS Network" />
        </Link>}
    </header>
  }
}

export default props => (
  <StaticQuery
    query={graphql`
      query {
        sportContent: allMarkdownRemark(filter: { fileAbsolutePath: { regex:"\/sportsContent/" } }) {
          edges {
            node {
              frontmatter {
                title
                icon{
                  name
                  extension
                  relativePath
                  publicURL
                }
              }
            }
          }
        }
      }
    `}
    render={data => <Header data={data} {...props} />}
  />
)

export const query = graphql`
fragment HeaderTranslations on TextsYaml{
  sports_title
  pricing_title
  support_title
  user_stories_title
  blog_title
  resources_title
  downloads_title
  social_media_title
  blog {
    features_title
  }
  try_xps {
    try_xps_for_free
    no_credit_card
  }
  sports {
    basketball
    field_hockey
    floorball
    handball
    ice_hockey
    other_sports
    performance
    soccer
    tennis
    volleyball
  }
}
`
